@import url("https://fonts.googleapis.com/css2?family=Zen+Tokyo+Zoo&display=swap");

.complete-logo {
  margin: auto;
  /* background: blue; */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: fit-content;
}

.arta {
  font-family: "Zen Tokyo Zoo", cursive;
  color: white;
  font-size: 3rem;
  /* z-index: 1; */
}

.hexagon-container {
  width: 120px;
  height: 120px;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
