@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

.logo-letter {
  font-family: "Monoton", cursive;
  color: black;
  font-size: 4rem;
  z-index: 1;
}

.hexagon {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 4s ease-in-out infinite;
  /* margin: 0 auto; */

  background: linear-gradient(to left, DarkBlue, DodgerBlue);
  border-radius: 12px;
  width: 100px;
  height: 63px;
  box-sizing: border-box;
  transition: all 1s;
  border: 1px solid transparent;
  border-top-color: dodgerblue;
  border-bottom-color: dodgerblue;
}

.hexagon:before,
.hexagon:after {
  content: "";
  border: inherit;
  position: absolute;

  background: linear-gradient(to left, DarkBlue, DodgerBlue);
  border-radius: inherit;
  height: inherit;
  width: inherit;
}

.hexagon:before {
  transform: rotate(60deg);
}

.hexagon:after {
  transform: rotate(-60deg);
}

@keyframes rotation {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  62.5%,
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}
